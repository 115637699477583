import React from "react";

export const Radios = props => (
    props.radios.map((element, index) => (
            <div key={element.ID} className="form-check d-flex">
                <input
                    className="form-check-input"
                    onChange={props.onChange}
                    type="radio"
                    id={"input-" + element.ID}
                    name={props.name}
                    value={element.ID}
                    checked={props.checked === element.ID}/>
                <label
                    className="form-check-label mx-3"
                    htmlFor={"input-" + element.ID}>{element.name}</label>
            </div>
        )
    )
);
