import React, {useState, useEffect} from "react";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru                           from "date-fns/locale/ru";
import PropTypes                    from "prop-types";
import {Helpers}                    from "../helpers/Helpers";

registerLocale("ru", ru);

export const BirthDatePicker = props => {

    const minusYears = 10;
    const [selected, setSelected] = useState(Helpers.getDateFromString(props.selected));

    let setMinusYears = (minusYears) => {
        let today = new Date();
        today.setDate(today.getDate() - 365 * minusYears);
        return today;
    };

    let setDateValue = (date) => {
        setSelected(date);
        props.onChange(date);
    };

    return (
        <DatePicker
            className="form-control"
            autoComplete={"Off"}
            id={props.name}
            name={props.name}
            dateFormat={props.dateFormat}
            locale="ru"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            maxDate={new Date()}
            dropdownMode="select"
            value={selected ? selected : ""}
            placeholderText="Кликните для выбора даты!"
            openToDate={selected ? selected : setMinusYears(minusYears)}
            selected={selected ? selected : setMinusYears(minusYears)}
            onChange={date => setDateValue(date)}
        />
    );
};

BirthDatePicker.propTypes = {
    name: PropTypes.string,
    selected: PropTypes.string,
    dateFormat: PropTypes.string,
    onChange: PropTypes.func,
};
