import React from "react";

export const HelpButton = props => (
    <a className="text-primary"
       href="#"
       title="помощь по разделу"
       data-load-url={props.dataLoadUrl}
       data-bs-toggle="modal"
       data-target="#helpModal">
        <span className="help-button">?</span>
    </a>

);

