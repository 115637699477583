import React, {Fragment, useEffect, useState} from "react";
import PropTypes, {string} from "prop-types";
import noPhotoMale         from '../../../res/img/users/user-no-photo-m.png';
import noPhotoFemale       from '../../../res/img/users/user-no-photo-f.png';
import noPhotoUndefined    from '../../../res/img/users/user-no-photo-n.png';
import {ErrorsHandler}     from "../dom/ErrorsHandler";
import {Spinner}           from "../dom/Spinner";
import {Utils}             from "../Utils";
import {app}               from "../../index";

const axios = require("axios").default;

export const PersonPhoto = props => {
    let canDelete = !!props.photoSrc;
    const [errors, setErrors] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const fileExtensions = app.config.PERSON_PHOTO_ALLOW_EXTENSION;
    const fileSize = app.config.PERSON_PHOTO_ALLOW_SIZE;

    useEffect(() => {
    });

    const getDefaultPic = () => {
        let curPhotoSrc;
        switch (props.gender) {
            case "m":
                curPhotoSrc = noPhotoMale;
                break;
            case "f":
                curPhotoSrc = noPhotoFemale;
                break;
            default:
                curPhotoSrc = noPhotoUndefined;
                break;
        }
        return curPhotoSrc;
    };

    const onPhotoChange = event => {
        let file = event.target.files?.[0],
            curErrors = [];
        setErrors([]);

        if (file) {
            let ext = file.name.split('.');
            ext = ext[1].split('/')[0];

            if (!fileExtensions.includes(ext)) {
                curErrors.push("Неверный формат файла - только изображения!");
            }

            if (file.size > fileSize) {
                curErrors.push("Превышен размер файла!");
            }

            if (curErrors.length > 0) {
                setErrors(curErrors);
                return false;
            }

            let tmpUrl = URL.createObjectURL(file);
            document.getElementById("personPhoto").src = tmpUrl;
            props.onPhotoChange(tmpUrl);

        }

    };

    const loadPhoto = () => {
        let input = document.getElementById(props.fileInputId);
        input.click();
    };

    const removePhoto = () => {
        props.onPhotoChange('');
    };

    return (
        <Fragment>
            {isLoading ? (
                <div className="person-photo">
                    <Spinner/>
                </div>
            ) : (
                <img id={"personPhoto"}
                     className="person-photo"
                     alt={"фото пользователя"}
                     title={"фото пользователя"}
                     src={props.photoSrc || getDefaultPic()}/>
            )}
            {props.edit ? (
                <div className="text-center my-3">
                    <div className="text-muted small mb-3">
                        Изображение не более {Utils.formatSize(fileSize)}
                    </div>
                    <input type="hidden" id={"deletedFile"} name={"deletedFile"} value={props.sourcePersonPhoto || ""}/>
                    <input type="file" className="d-none" accept="image/*" id={props.fileInputId}
                           name={props.fileInputId} onChange={onPhotoChange}/>
                    <div className="text-center">
                        <input type="button" className="btn btn-primary btn-sm me-2 mb-2" onClick={loadPhoto}
                               value="изменить"/>
                        {canDelete ? (
                            <input type="button"
                                   className={"btn btn-danger btn-sm mb-2"}
                                   onClick={removePhoto}
                                   value="Удалить &#215;"/>
                        ) : ""}
                        <div className={"small mt-3"}>
                            <ErrorsHandler errors={errors}/>
                        </div>
                    </div>
                </div>
            ) : ""}
        </Fragment>
    );
};

PersonPhoto.propTypes = {
    gender: PropTypes.string,
    photoSrc: PropTypes.string,
    sourcePersonPhoto: PropTypes.string,
    fileInputId: PropTypes.string,
    edit: PropTypes.bool,
    onPhotoChange: PropTypes.func,
};
