import React       from "react";
import PropTypes   from "prop-types";

export const ErrorsHandler = ({errors}) => (
    <>
        {(errors && errors.length > 0) ? (
            <div className='alert alert-danger'>
                {
                    errors.map((error) => {
                        if (error.length > 0) {
                            return (
                                <div key={i}>{error}</div>
                            )
                        } else {
                            return '';
                        }
                    })
                }
            </div>
        ) : ("")}
    </>
);

ErrorsHandler.propTypes = {
    errors: PropTypes.array,
};
